import { clearAllCookies } from '../сookies/cookies';
import { clearLocalStorage } from '../storage/storage';

export const logOut = () => {
    clearAllCookies();
    clearLocalStorage();
    
    if (typeof window !== 'undefined') {
        window.location.href = '/';
    }
};
