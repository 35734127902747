import { all } from 'redux-saga/effects';
import { cartSaga } from './cart/sagas';
import { wishlistSaga } from './wishlist';
import { appSaga } from './app';
import { categoriesSaga } from './categories';
import { checkoutSaga } from './checkout/sagas';
import { customerSaga } from './customer/sagas';

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    cartSaga(),
    customerSaga(),
    appSaga(),
    wishlistSaga(),
    categoriesSaga(),
    checkoutSaga(),
  ]);
}
