export const actionTypes = {
  GetCurrentCustomer: 'GET_CURRENT_CUSTOMER',
  GetCurrentCustomerSuccess: 'GET_CURRENT_CUSTOMER_SUCCESS',
  GetCurrentCustomerFailure: 'GET_CURRENT_CUSTOMER_FAILURE',

  SetCustomerAddress: 'SET_CUSTOMER_ADDRESS',

  SetCustomerState: 'SET_CUSTOMER_State',

};
