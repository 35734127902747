import { actionTypes } from './constants';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  cartItems: [],
  availableCartItems: [],
  isAvailabilityChecked: false,
  timesCartItems: {},
  cart_id: '',
  cart_amount: 0,
  customer_id: '',
  shipping_cost: 0,
  tax_amount: 0,
  discount_amount: 0,
  saved_amount: 0,
  base_amount: 0
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AddToCart:
      const newQty = parseInt(action.payload.newQty);
      const { sku } = action.payload.product;
      const productIndex = state.cartItems.findIndex(
        product => product.sku === sku
      );
      if (productIndex !== -1) {
        const product = state.cartItems[productIndex];
        const qty = product.qty + newQty;
        const sum = (product.sale_price ?? product.price) * qty;

        const updatedProduct = {
          ...product,
          qty,
          sum
        };

        const cartItems = [...state.cartItems];
        cartItems.splice(productIndex, 1, updatedProduct);

        return {
          ...state,
          timesCartItems: cartItems
        };
      } else {
        const cartItem = {
          ...action.payload.product,
          qty: newQty,
          sum:
            (action.payload.product.sale_price
              ? action.payload.product.sale_price
              : action.payload.product.price) * newQty
        };

        return {
          ...state,
          timesCartItems: [...state.cartItems, cartItem],
          isAvailabilityChecked: false
        };
      }

    case actionTypes.RemoveFromCart:
      return {
        ...state,
        timesCartItems: action.payload,
        isAvailabilityChecked: false
      };
    case actionTypes.UpdateCart:
      return {
        ...state,
        timesCartItems: action.payload,
        isAvailabilityChecked: false
      };
    case actionTypes.RefreshCartStore:

      return {
        ...state,
        cartItems: action.payload.cartItems || [],
        timesCartItems: {},
        isAvailabilityChecked: false,
        availableCartItems: action.payload.availableCartItems,
        cart_id: action.payload.cart_id,
        cart_amount: action.payload.cart_amount,
        customer_id: action.payload.customer_id,
        shipping_cost: action.payload.shipping_cost,
        tax_amount: action.payload.tax_amount,
        discount_amount: action.payload.discount_amount || 0,
        saved_amount: action.payload.saved_amount || 0,
        base_amount: action.payload.base_amount || 0
      };
    case actionTypes.GetActualCart:
      return state;

    case actionTypes.SetAvailableCartItems:
      return {
        ...state,
        availableCartItems: action.payload,
        isAvailabilityChecked: true
      };

    case actionTypes.SetCartItems:
      return {
        ...state,
        cartItems: action.payload,
      };

    case actionTypes.SetInitialCartState:
      return initialState;

    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: 'porto-',
  key: 'cart',
  storage: storage
};

export default persistReducer(persistConfig, cartReducer);
