import React, { useEffect } from 'react';
import { Provider, useStore } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useRouter } from 'next/router';
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/ReactToastify.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { wrapper } from '../store';
import Layout from '../components/layout';
import '../public/sass/style.scss';
import SecondaryLayout from '../components/SecondaryLayout';
import { useAuthModalStore } from '../store/auth-modal';
import { useAxios } from '../hooks/useAxios/useAxios';
import { NotificationCenter } from '../components/partials/notification-center/notification-center';
import { ToastContainer } from 'react-toastify';
import { logRocketInit } from '../utils/logrocket';
import Head from 'next/head';
import Script from 'next/script';
import { handleBeforeUnload } from '../utils';
import versionConfig from '../environments/versionConfig';
import ErrorBoundary from '../components/ErrorBoundary';
import ErrorPage from './error'

logRocketInit();

const App = ({ Component, pageProps }) => {
  const store = useStore();
  const router = useRouter();
  const params = router.query

  const {
    monitorUserSessionFromCookie,
    setUserData,
    ...state
  } = useAuthModalStore();

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []);

  useAxios(
    '/public/buyontrust/auth/current_customer?store=furniture',
    { method: 'post' },
    state.isUserLogged,
    data => {
      if (data) {
        localStorage.setItem('customerData', JSON.stringify(data));
        setUserData(data);
      }
    }
  );

  useEffect(() => {
    console.log(
      `App launched on environment:  = ${process.env.TARGET_ENVIRONMENT}, version ${versionConfig.version}`
    );

    // if (store.getState().demo.current !== 8) {ç
    //     store.dispatch(DemoAction.refreshStore(8));
    // }
  }, []);

  useEffect(() => {
    if (params?.lp) {
      sessionStorage.setItem('lp', params.lp);
    }
  }, [params?.lp])

  useEffect(() => {
    monitorUserSessionFromCookie();
  }, [state.isUserLogged, state.ddToken, state.jwtToken]);

  const CurrentLayout = router.pathname === '/' ? SecondaryLayout : Layout;

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Provider store={store}>
        <PersistGate
          // loading={
          //   <div className="loading-overlay">
          //     <div className="bounce-loader">
          //       <div className="bounce1" />
          //       <div className="bounce2" />
          //       <div className="bounce3" />
          //     </div>
          //   </div>
          // }
          persistor={store.__persistor}
        >
          {() => (
            <>
              <Head>
                <meta charSet="UTF-8"/>
                <meta
                  content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
                  name="viewport"
                />

                <title>Buy On Trust</title>

                <meta
                  content="React Template"
                  key="keywords"
                  name="keywords"
                />
                <meta
                  content="Buy On Trust"
                  key="desc"
                  name="description"
                />

                <meta
                  content="Buy On Trust"
                  key="og:title"
                  property="og:title"
                />
                <meta
                  content="Your ideal furniture in a click"
                  key="og:desc"
                  property="og:description"
                />
                <meta
                  content={`${process.env.FURNITURE_URL}/images/banners/tom-unsplash.jpg`}
                  key="og:img"
                  property="og:image"
                />
                <meta
                  content="1200"
                  property="og:image:width"
                />
                <meta
                  content="630"
                  property="og:image:height"
                />
                <meta
                  content="website"
                  key="og:type"
                  property="og:type"
                />
                <meta
                  content="Buy On Trust"
                  key="og:site"
                  property="og:site_name"
                />

              </Head>

              {/* Google Tag Manager Script */}
              <Script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-PK103YB8SX"
              />
              <Script
                id="katapult-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                    var _katapult_config = {
                      api_key: "${process.env.NEXT_PUBLIC_KATAPULT_API_KEY}",
                      environment: "${process.env.NEXT_PUBLIC_KATAPULT_BASE_URL}"
                    };
                    !function(e,t){e.katapult=e.katapult||{};var n,i,r;i=!1,n=document.createElement('script'),n.type='text/javascript',n.async=!0,n.src=t.environment+'/'+'plugin/js/katapult.js',n.onload=n.onreadystatechange=function(){i||this.readyState&&'complete'!=this.readyState||(i=!0,e.katapult.setConfig(t.api_key))},r=document.getElementsByTagName('script')[0],r.parentNode.insertBefore(n,r);var s=document.createElement('link');s.setAttribute('rel','stylesheet'),s.setAttribute('type','text/css'),s.setAttribute('href',t.environment+'/'+'plugin/css/katapult.css');var a=document.querySelector('head');a.insertBefore(s,a.firstChild)}(window,_katapult_config);
                  `
                }}
              />
              <Script
                dangerouslySetInnerHTML={{
                  __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-PK103YB8SX');
              `
                }}
                id="gtag-init"
              />

              <Script
                async
                src="https://www.googletagmanager.com/gtag/js?id=AW-611256288"
              />
              <Script
                dangerouslySetInnerHTML={{
                  __html: `
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'AW-611256288');
                    
                    function gtag_report_conversion(amount) {
                      console.log('=== gtagSendConversionEvent START ===');
                      gtag('event', 'conversion', {
                       'send_to': 'AW-611256288/9GoVCN3JxN4ZEOCPvKMC',
                       'value': amount,
                       'currency': 'USD',
                    });
                    }
                    `
                }}
                id="gtag-conversion"
              />

              {/** Test g-tag */}
              <Script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-EK1TGECWSM"
              />
              <Script
                dangerouslySetInnerHTML={{
                  __html: `
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-EK1TGECWSM');
                    
                    function gtag_report_conversion_test(amount) {
                      console.log('=== gtagSendConversionEvent TEST START ===');
                      gtag('event', 'conversion', {
                       'send_to': 'G-EK1TGECWSM/9856906506',
                       'value': amount,
                       'currency': 'USD',
                    });
                    }
                    `
                }}
                id="gtag-conversion-test"
              />

              <CurrentLayout>
                <Component {...pageProps} />
              </CurrentLayout>
            </>
          )}
        </PersistGate>
        <NotificationCenter />
        <ToastContainer
          autoClose={3000}
          hideProgressBar
          newestOnTop
          theme="colored"
        />
      </Provider>
    </ErrorBoundary>
  );
};

// App.getInitialProps = async ({ Component, ctx }) => {
//     let pageProps = {};
//     if (Component.getInitialProps) {
//         pageProps = await Component.getInitialProps(ctx);
//     }
//     return { pageProps };
// };

export default wrapper.withRedux(App);
