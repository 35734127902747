import LogRocket from 'logrocket';

// eslint-disable-next-line no-undef
const logRocketInitValue = process.env.NEXT_APP_LOG_ROCKET_INIT || '';
const nodeEnv = process.env.TARGET_ENVIRONMENT || process.env.NODE_ENV;

export const logRocketInit = function() {
  if (nodeEnv !== 'development' /*&& nodeEnv !== 'staging'*/) {
    console.log(' -- logRocketInit = ' + logRocketInitValue);
    // window.LogRocket && window.LogRocket.init(logRocketInitValue);
    LogRocket.init(logRocketInitValue, {
      mergeIframes: true,
      parentDomain: 'https://f.buyontrust.com/checkout'
    });
  }
};

export const initUserTrack = function(hsid, phone, first_name, last_name, email) {
  if (nodeEnv !== 'development' /*&& nodeEnv !== 'staging'*/) {

    const userInfo = {
      name: `${first_name} ${last_name}`,
      email: email,
      phone: phone
    };

    console.log('initUserTrack',
      hsid,
      new Date().toString(),
      userInfo
    );

    if (phone) {
      LogRocket.identify(hsid, userInfo);
    } else {
      LogRocket.identify(hsid);
    }

    // if (typeof window.LogRocket?.identify === 'function') {
    //   if (phone) {
    //     // console.log('initUserTrack => phone = ' + phone);
    //     // console.log('initUserTrack => full_name = ' + full_name);
    //     // console.log('initUserTrack => email = ' + email);
    //
    //     if (window.LogRocket) {
    //       window.LogRocket.identify(phone, {
    //         name: full_name,
    //         email: email,
    //       });
    //     }
    //
    //   }
    // }
  }
};

export const eventTrack = function(event_name, data = {}) {
  if (nodeEnv !== 'development' && nodeEnv !== 'staging') {
    // if (typeof window.LogRocket?.track === 'function') {
    //   if (window.LogRocket) {
    //     window.LogRocket.track(event_name);
    //   }
    // }
    LogRocket.track?.(event_name, data);
  }
};

export const captureExceptionTrack = function(err, method_name, extra = {}) {
  if (nodeEnv !== 'development' && nodeEnv !== 'staging') {
    // if (typeof window.LogRocket?.captureException === 'function') {
    //
    //   let extra_data={};
    //
    //   for (let key in extra) {
    //     if (Object.prototype.hasOwnProperty.call(extra, key)) {
    //       const value = extra[key];
    //
    //       if (value) {
    //         try {
    //           extra_data[key] = JSON.stringify(value);
    //         } catch {}
    //       }
    //     }
    //   }
    //
    //   if (window.LogRocket) {
    //     window.LogRocket.captureException(err, {
    //       tags: {
    //         // additional data to be grouped as "tags"
    //         method: method_name,
    //       },
    //       extra: extra_data
    //     });
    //   }
    // }

    let extra_data = {};

    for (let key in extra) {
      if (Object.prototype.hasOwnProperty.call(extra, key)) {
        const value = extra[key];

        if (value) {
          try {
            extra_data[key] = JSON.stringify(value);
          } catch {}
        }
      }
    }

    LogRocket.captureException(err, {
      tags: {
        // additional data to be grouped as "tags"
        method: method_name
      },
      extra: extra_data
    });
  } else {
    console.error(err);
  }
};
