import { create } from 'zustand';

const useApproveModalStore = create(set => ({
  isOpen: false,
  fromFooter: false,
  openModal: () => set({ isOpen: true, fromFooter: false }),
  openFromFooter: () => set({ isOpen: true, fromFooter: true }),
  closeModal: () => set({ isOpen: false, fromFooter: false })
}));

export default useApproveModalStore;
