import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { setLocalStorage } from '../../../utils/storage/storage';
import { useRouter } from 'next/router';

const environment = process.env.TARGET_ENVIRONMENT;
const uownSrc = process.env.NEXT_PUBLIC_UOWN_BASE_URL || '';
const approvalSrc = process.env.NEXT_PUBLIC_APPROVAL_BASE_URL || '';

export default function HowItWorks({
  isChecked,
  setIsChecked,
  setIsOpenModal,
  setIsShowLeaseModal,
  handleClose
}) {
  const [isApproved, setIsApproved] = useState(false);
  const [isGtagSent, setIsGtagSent] = useState(false);

  const customerEmail = JSON.parse(localStorage.getItem('customerData'))
    ?.customer?.email;

  const router = useRouter();

  const handleModifyCart = () => {
    window.location.href = '/cart';
  };
  const handleOpen = () => {
    setLocalStorage('showHowItWorks', isChecked);
    // setIsOpenModal(false); // <-- we need this modal opened to catch messages from iframe !!!
    setIsShowLeaseModal(true);
  };

  useEffect(() => {
    const customerData = localStorage.getItem('customerData');

    if (customerData) {
      const parsedData = JSON.parse(customerData);
      const leaseData = parsedData.lease_data || [];
      const approvedLease = leaseData.find(
        lease => (lease?.name === 'uown_furniture' || lease?.name === 'katapult') && lease?.application_status
      );
      if (approvedLease?.application_status === 'approved' && approvedLease?.approval_amount > 0) {
        setIsApproved(true);
      }
    }
  }, []);

  const reportConversion = (applcationStatus, approvalAmount, event) => {
    console.log('sendGtagReports', JSON.stringify({on: event, applcationStatus, approvalAmount, isGtagSent}))

    if (environment === 'production' && applcationStatus === 'approved') {
      reportActualConversion(approvalAmount);
      reportTestConversion(approvalAmount);
    }
  }

  const reportActualConversion = (approvalAmount) => {
    const conversionFunctionType = typeof window.gtag_report_conversion
    if (conversionFunctionType !== 'function') {
      console.error(`gtag_report_conversion is not a function but ${conversionFunctionType}`);
    } else {
      const amount = approvalAmount || 0;
      window.gtag_report_conversion(amount);
      setIsGtagSent(true);
      console.log('gtag_report_conversion is sent with amount =', amount);
    }
  };

  const reportTestConversion = (approvalAmount) => {
    const conversionFunctionType = typeof window.gtag_report_conversion_test
    if (conversionFunctionType !== 'function') {
      console.error(`gtag_report_conversion_test is not a function but ${conversionFunctionType}`);
    } else {
      const amount = approvalAmount || 0;
      window.gtag_report_conversion_test(amount);
      console.log('gtag_report_conversion_test is sent with amount =', amount);
    }
  }

  /** Call send test g-tag when test button is clicked */
  const onGTagTestButtonClick = () => {
    reportTestConversion(1000000);
  }

  const onMessageReceivedFromIframe = event => {
    if (event.origin === uownSrc || event.origin === approvalSrc) {
      console.log('--- HowItWorks => onMessageReceivedFromIframe ---');
      console.log(event);

      const timeStamp = new Date().toString().substring(4, 33);

      if (!event.data) {
        console.log('MessageData: no data received');
      } else if (typeof event.data === 'string') {
        console.log(
          `MessageData: ${event.data} (${customerEmail}, ${timeStamp})`
        );
      } else {
        console.log(
          `MessageData: ${JSON.stringify(
            event.data
          )} (${customerEmail}, ${timeStamp})`
        );
      }

      try {
        switch(event.data.label) {
          case 'uown-reload-checkout': {
            console.log('HowItWorks => onMessageReceivedFromIframe --> uown-reload-checkout')
            handleModifyCart() // Redirect to cart for modify
            break
          }
          case 'uown-finished': {
            // The application was successfully completed
            console.log('HowItWorks => onMessageReceivedFromIframe --> uown-finished')
            const { application_status, approval_amount } = event.data.info;
            reportConversion(application_status, approval_amount, event.data.label)
            break
          }
          case 'uown-finalize':
          case 'katapult-finalize':
          case 'katapult-got-it': {
            // The application was successfully completed FOR NOT LOGGED IN CUSTOMER
            console.log(
              `HowItWorks => onMessageReceivedFromIframe --> ${event.data.label}`
            );

            if (!isGtagSent) {
              const { application_status, approval_amount } = event.data.info;
              reportConversion(application_status, approval_amount, event.data.label)
            }

            router.reload()
            setIsOpenModal(false);
            setIsShowLeaseModal(false);
            break
          }
          default:
            // not interested in others
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe, false);

    return () => {
      window.removeEventListener('message', onMessageReceivedFromIframe);
    };
  }, []);

  return (
    <div className="how-it-works overflow-auto text-center">
      <div className="p-4">
        <h3 className="how-it-works-title">
          {isApproved
            ? 'It looks like you have been approved'
            : 'It looks like you haven\'t been approved'}
        </h3>
        <p className="how-it-works-desc">
          With a small initial payment, usually less than <strong>$50,</strong>{' '}
          you can afford to shop from all the top brand names.
          <br />
          <br />
          Don't wait, find out how much you qualify for right now.
        </p>
      </div>
      <h4 className="how-it-works-sub mt-2">HOW IT WORKS</h4>
      <div className="how-it-works-wrapper d-flex">
        <div className="how-it-works-card">
          <LazyLoadImage
            alt="Step 1"
            className="how-it-works-img"
            src="/images/howitworks1.png"
          />
          <div className="how-it-works-card-wrapper">
            <div>
              <span className="how-it-works-number">1</span>
            </div>
            <div>
              <h5 className="how-it-works-subtitle">
                Get approved for up to $5,000
              </h5>
              <p>The process is fast, easy, and approval is instant</p>
            </div>
          </div>
        </div>
        <div className="how-it-works-card">
          <LazyLoadImage
            alt="Step 2"
            className="how-it-works-img"
            src="/images/howitworks2.png"
          />
          <div className="how-it-works-card-wrapper">
            <div>
              <span className="how-it-works-number">2</span>
            </div>
            <div>
              <h5 className="how-it-works-subtitle">Start Shopping</h5>
              <p>Over 1 Million new, brand name, products</p>
            </div>
          </div>
        </div>
        <div className="how-it-works-card">
          <LazyLoadImage
            alt="Step 3"
            className="how-it-works-img"
            src="/images/howitworks3.png"
          />
          <div className="how-it-works-card-wrapper">
            <div>
              <span className="how-it-works-number">3</span>
            </div>
            <div>
              <h5 className="how-it-works-subtitle">Checkout</h5>
              <p>
                Provide a delivery address and sign your lease electronically.
              </p>
            </div>
          </div>
        </div>
        <div className="how-it-works-card">
          <LazyLoadImage
            alt="Step 4"
            className="how-it-works-img"
            src="/images/howitworks4.png"
          />
          <div className="how-it-works-card-wrapper">
            <div>
              <span className="how-it-works-number">4</span>
            </div>
            <div>
              <h5 className="how-it-works-subtitle">
                Your furniture is shipped directly to your home
              </h5>
              <p>You can track your delivery status online</p>
            </div>
          </div>
        </div>
      </div>
      <h4 className="how-it-works-simple">It's just that simple!</h4>
      {isApproved ? (
        <>
          <div className={'header-approval-button '}>
            <a
              className="pointer"
              onClick={handleClose}
              target="_blank"
            >
              Got it
            </a>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3 cla" />
        </>
      ) : (
        <>
          <div className={'header-approval-button '}>
            <a
              className="pointer"
              onClick={handleOpen}
              target="_blank"
            >
              Get&nbsp;Approved!
            </a>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3 cla">
            <label className="d-flex align-items-center how-it-works-label">
              <input
                checked={isChecked}
                className="how-it-works-checkbox"
                onChange={() => setIsChecked(!isChecked)}
                type="checkbox"
              />
              <span className="ml-3" />
              Don't show this message again
            </label>
          </div>
        </>
      )}

      {/** This button is not present on production. Use for sending test g-tag */}
      {environment !== 'production' &&
        <>
          <div className="d-flex justify-content-center align-items-center mt-3 cla" />
          <div className={'header-approval-button '}>
            <a
              className="pointer"
              onClick={onGTagTestButtonClick}
              target="_blank"
            >
            Send g-tag report conversion (test for not production environment)
            </a>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3 cla" />
        </>}

    </div>
  );
}
