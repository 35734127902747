import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getCategoriesRequest } from '../api/getCategories';
import { createSlice } from '@reduxjs/toolkit';
import { getSubCategoriesRequest } from '../api/getSubcategories';

const initialState = {
  categories: [],
  subCategories: [], 
  selectedCategory: '',
  childrenCategories: [],
  parentCategoryId: '',
  isLoading: false,
  errors: ''
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    getCategories(state) {
      state.isLoading = true;
      state.errors = '';
    },
    fetchCategoriesSucceeded(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },
    setCategory(state, action) {
      const parentCatId = action.payload?.parentId ?? '';
      state.selectedCategory = action.payload?.name;
      state.childrenCategories = action.payload?.children;
      state.parentCategoryId = parentCatId;
    },
    clearCategory(state) {
      state.selectedCategory = '';
      state.childrenCategories = [];
      state.parentCategoryId = '';
    },
    fetchCategoriesFailed(state, action) {
      state.isLoading = false;
      state.errors = action.payload;
    },
    getSubCategories(state) {
      state.isLoading = true;
      state.errors = '';
    },
    fetchSubCategoriesSucceeded(state, action) {
      state.isLoading = false;
      state.subCategories = action.payload;
    },
    fetchSubCategoriesFailed(state, action) {
      state.isLoading = false;
      state.errors = action.payload;
    }
  }
});

const { actions, reducer } = categoriesSlice;

export const {
  getCategories,
  fetchCategoriesSucceeded,
  setCategory,
  clearCategory,
  fetchCategoriesFailed,
  getSubCategories,
  fetchSubCategoriesSucceeded,
  fetchSubCategoriesFailed
} = actions;

export function* categoriesSaga() {
  yield takeEvery(getCategories, function* saga() {
    try {
      const resultFromReducer = yield select(state => state.categories);
      const response = yield call(getCategoriesRequest, resultFromReducer);
      yield put(fetchCategoriesSucceeded(response));
    } catch (error) {
      yield put(fetchCategoriesFailed(error));
    }
  });

  yield takeEvery(getSubCategories, function* saga() {
    try {
      const response = yield call(getSubCategoriesRequest);
      yield put(fetchSubCategoriesSucceeded(response.productAmountsBySubcategory));
    } catch (error) {
      yield put(fetchSubCategoriesFailed(error));
    }
  });
}

const persistConfig = {
  keyPrefix: 'porto-',
  key: 'categories',
  storage
};

export default persistReducer(persistConfig, reducer);