import React, { useCallback, useEffect, useState } from 'react';
import { useAuthModalStore } from '../../../store/auth-modal';
import { Icons, toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import styled from 'styled-components';

import { Trigger } from './trigger';
import { ItemActions } from './item-actions';
// import { Switch } from './switch';
import { TimeTracker } from './time-tracker';
import Image from 'next/image';
import ALink from '../../common/ALink';

const variants = {
  container: {
    open: {
      y: 0,
      opacity: 1
    },
    closed: {
      y: -10,
      opacity: 0
    }
  },
  content: {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  },
  item: {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  },
  closeButton: {
    position: 'relative',
    left: '55px',
    bottom: '-7px'
  }
};

// const UnreadFilter = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 8px;
//
//     label {
//         cursor: pointer;
//     }
// `;

const Container = styled(motion.aside)`
    width: min(60ch, 100ch);
    border-radius: 8px;
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;

    .aside {
        border: 1px solid black;
    }

    @media (max-width: 480px) {
        width: 97vw;
        max-width: 97vw;
    }
`;

const Footer = styled.footer`
    background: #222;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Content = styled(motion.section)`
    background: #fff;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    color: red;
    padding: 0.2rem;
    position: relative;

    h4 {
        margin: 0;
        text-align: center;
        padding: 2rem;
    }
`;

const IconWrapper = styled.div`
    width: 32px;
`;

const Item = styled(motion.article)`
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    gap: 8px;
    padding: 0.8rem;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

const Header = styled.header`
    background: #222;
    color: #fff;
    margin: 0 auto;
    padding: 1rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
        margin: auto;
        color: #fff;
    }
`;

export function NotificationCenter() {
  const { setNotificationCenter, ...state } = useAuthModalStore();
  const {
    notifications,
    clear,
    markAllAsRead,
    markAsRead,
    remove,
    unreadCount
  } = useNotificationCenter({ filter: (item) => item.type === 'error' });
  const [showUnreadOnly, toggleFilter] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (unreadCount > 0) {
      setNotificationCenter(true);
    }
  }, [unreadCount, setNotificationCenter]);

  // const hideNotificationCenter = useCallback(() => {
  //   setShowNotificationCenter(false);
  // }, [setShowNotificationCenter]);

  const hideNotificationCenter = useCallback(() => {
    setNotificationCenter(false);
  }, [setNotificationCenter]);

  return (
    <>
      {state?.showNotificationCenter && (
        <div style={{ position: 'fixed', left: '6px', bottom: '46px', zIndex: 99999999999 }}>
          <div style={{
            position: 'relative',
            left: '30px',
            bottom: '-8px',
            background: 'none',
            borderRadius: '10px',
            width: '12px',
            height: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          >
            <ALink
              className="icon-close"
              href="#"
              onClick={hideNotificationCenter}
              target="_blank"
            >
              <Image
                alt="notification center close icon"
                height={8}
                src={'/images/close.svg'}
                width={8}
              />
            </ALink>
          </div>
          <Trigger
            count={unreadCount}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      )}
      <div style={{
        display: isOpen ? 'block' : 'none',
        position: 'fixed',
        bottom: '80px',
        left: '6px',
        zIndex: 99999999999
      }}>
        <Container
          animate={isOpen ? 'open' : 'closed'}
          className="aside"
          initial={false}
          variants={variants.container}
        >
          <Header>
            <div>
              <h3>Error&nbsp;messages</h3>
            </div>
            {/*<UnreadFilter>*/}
            {/*  <label htmlFor="unread-filter">Only show unread</label>*/}
            {/*  <Switch*/}
            {/*    checked={showUnreadOnly}*/}
            {/*    id="unread-filter"*/}
            {/*    onChange={() => {*/}
            {/*      toggleFilter(!showUnreadOnly);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</UnreadFilter>*/}
            <ALink
              className="social-icon social-x icon-x"
              href="#"
              onClick={() => setIsOpen(!isOpen)}
              target="_blank"
              title="Twitter"
            >
              <Image
                alt="notification center close icon"
                height={14}
                src={'/images/close-white.svg'}
                width={14}
              />
            </ALink>
          </Header>
          <AnimatePresence>
            <Content
              animate={isOpen ? 'open' : 'closed'}
              className="aside"
              variants={variants.content}
            >
              {(!notifications.length ||
                (unreadCount === 0 && showUnreadOnly)) && (
                <motion.h4
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                >
                  There&nbsp;are&nbsp;no&nbsp;messages
                </motion.h4>
              )}
              <AnimatePresence>
                {(showUnreadOnly
                  ? notifications.filter((v) => !v.read)
                  : notifications
                ).map((notification) => {
                  return (
                    <motion.div
                      animate={{ scale: 1, opacity: 1, y: 0 }}
                      exit={{
                        scale: 0,
                        opacity: 0,
                        transition: { duration: 0.2 }
                      }}
                      initial={{ scale: 0.4, opacity: 0, y: 50 }}
                      key={notification.id}
                      layout
                      style={{ padding: '0.8rem' }}
                    >
                      <Item key={notification.id} variants={variants.item}>
                        <IconWrapper>
                          {notification.icon ||
                            Icons.info({
                              theme: notification.theme || 'light',
                              type: toast.info
                            })}
                        </IconWrapper>
                        <div>
                          <div>{notification.content}</div>
                          <TimeTracker createdAt={notification.createdAt} />
                        </div>
                        <ItemActions
                          markAsRead={markAsRead}
                          notification={notification}
                          remove={remove}
                        />
                      </Item>
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </Content>
          </AnimatePresence>
          <Footer>
            <a className="pointer borderer" onClick={clear}>Clear&nbsp;All</a>
            <a className="pointer borderer" onClick={markAllAsRead}>Mark&nbsp;All&nbsp;as&nbsp;read</a>
          </Footer>
        </Container>
      </div>
    </>
  );
}
