export const iframeModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    zIndex: 3000
  },
  content: {
    zIndex: 3000,
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    height: '100%',
    maxHeight: 800,
    border: 'none',
    borderRadius: 10,
    overflow: 'hidden',
    padding: 0,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column'
  }
};

export const faultsWrapper = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    zIndex: 3000
  },
  content: {
    zIndex: 3000,
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    height: 'auto',
    border: 'none',
    borderRadius: 4,
    overflow: 'hidden',
    padding: 30,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column'
  }
};

export const HowItWorksStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    zIndex: 3000
  },
  content: {
    zIndex: 3000,
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '95%',
    maxWidth: '1350px',
    border: 'none',
    borderRadius: 4,
    overflow: 'hidden',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  }
};
