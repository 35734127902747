import Modal from "react-modal";
import {iframeModalStyles} from '../../../themes/iframe-modal'

export default function IframeModal(props) {
  const {children, isOpen, onRequestClose, styles = iframeModalStyles} = props;

  return <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={styles}>
    <div className="modal-header-wrapper">
      <button className="mfp-close" onClick={onRequestClose}>×</button>
    </div>
    {children}
  </Modal>;
}
