import { useRouter } from 'next/navigation';
import ALink from '../components/common/ALink';

const ErrorPage = () => {
  const router = useRouter();

  return (
    <main className="main">
      <div className="container">
        <section className="section">
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <ALink href="/">
                  <i className="sicon-home"></i>
                </ALink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Error
              </li>
            </ol>
          </nav>
          <div className="d-flex flex-column align-items-center py-3">
            <div className="col-md-7 text-center">
              <h3 className="subpage__title">Oops!</h3>
              <p className="font-weight-bold m-2 subpage__paragraph">
                Something went wrong here.
              </p>
              <p className="subpage__paragraph">
                We`re working on it and we`ll get it fixed as soon as possible.
                Please go back or use our Help Center.
              </p>
            </div>

            <ALink
              href="/home"
              onClick={() => window.location.href = `${process.env.FURNITURE_URL}/home`}
              className="error-link">
              Home
            </ALink>
          </div>
        </section>
      </div>
    </main>
  );
};

export default ErrorPage;
