import { withRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

// Import Custom Component
import ALink from '../ALink';
import { mainMenu } from '../../../utils/data/menu';
import { Collapse } from '@kunukn/react-collapse';

function MobileMenu({ router }) {
  const pathname = router.pathname;
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    router.events.on('routeChangeStart', closeMobileMenu);

    return () => {
      router.events.off('routeChangeStart', closeMobileMenu);
    };
  }, []);

  function isOtherPage() {
    return mainMenu.other.find(variation => variation.url === pathname);
  }

  function closeMobileMenu() {
    document.querySelector('body').classList.remove('mmenu-active');

    if (document.querySelector('.menu-toggler')) {
      document.querySelector('.menu-toggler').classList.remove('active');
    }
  }

  function searchProducts(e) {
    e.preventDefault();
    router.push({
      pathname: '/shop',
      query: {
        search: searchText
      }
    });
  }

  function onChangeSearchText(e) {
    setSearchText(e.target.value);
  }

  return (
    <>
      <div className="mobile-menu-overlay" onClick={closeMobileMenu}></div>
      <div className="mobile-menu-container">
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close" onClick={closeMobileMenu}>
            <i className="fa fa-times"></i>
          </span>
          <nav className="mobile-nav">
            <ul className="mobile-menu">
              <li className={pathname === '/' ? 'active' : ''}>
                <ALink href="/">Home</ALink>
              </li>
              <li className={pathname.startsWith('/shop') ? 'active' : ''}>
                {
                  <>
                    <button>Toggle Content</button>

                    {/* <Collapse
                                            isOpen={true}
                                            transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)">
                                            {({
                                                onToggle,
                                                setCollapsibleElement,
                                                toggleState
                                            }) => (
                                                <>
                                                    <ALink
                                                        href="/shop"
                                                        className={`${toggleState.toLowerCase()}`}>
                                                        Categories
                                                        <span
                                                            className="mmenu-btn"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                onToggle();
                                                            }}></span>
                                                    </ALink>

                                                    <ul
                                                        ref={
                                                            setCollapsibleElement
                                                        }>
                                                        <li>
                                                            <>
                                                                <button>
                                                                    Toggle
                                                                    Content
                                                                </button>
                                                                <Collapse
                                                                    isOpen={
                                                                        true
                                                                    }
                                                                    transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)">
                                                                    {({
                                                                        onToggle,
                                                                        setCollapsibleElement,
                                                                        toggleState
                                                                    }) => (
                                                                        <>
                                                                            <a
                                                                                href="#"
                                                                                className={`${toggleState.toLowerCase()}`}>
                                                                                VARIATION
                                                                                1
                                                                                <span
                                                                                    className="mmenu-btn"
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        onToggle();
                                                                                    }}></span>
                                                                            </a>
                                                                            <ul
                                                                                ref={
                                                                                    setCollapsibleElement
                                                                                }>
                                                                                {mainMenu.shop.variation1.map(
                                                                                    (
                                                                                        variations,
                                                                                        index
                                                                                    ) => (
                                                                                        <li
                                                                                            key={
                                                                                                'menu-item' +
                                                                                                index
                                                                                            }>
                                                                                            <ALink
                                                                                                href={`${variations.url}`}>
                                                                                                {
                                                                                                    variations.title
                                                                                                }
                                                                                            </ALink>
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </>
                                                                    )}
                                                                </Collapse>
                                                            </>
                                                        </li>

                                                        <li>
                                                            <Collapse
                                                                expanded={true}
                                                                trigger={
                                                                    <button>
                                                                        Toggle
                                                                        Content
                                                                    </button>
                                                                }>
                                                                {({
                                                                    onToggle,
                                                                    setCollapsibleElement,
                                                                    toggleState
                                                                }) => (
                                                                    <>
                                                                        <a
                                                                            href="#"
                                                                            className={`${toggleState.toLowerCase()}`}>
                                                                            VARIATION
                                                                            2
                                                                            <span
                                                                                className="mmenu-btn"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    onToggle();
                                                                                }}></span>
                                                                        </a>
                                                                        <ul
                                                                            ref={
                                                                                setCollapsibleElement
                                                                            }>
                                                                            {mainMenu.shop.variation2.map(
                                                                                (
                                                                                    variations,
                                                                                    index
                                                                                ) => (
                                                                                    <li
                                                                                        key={
                                                                                            'menu-item' +
                                                                                            index
                                                                                        }>
                                                                                        <ALink
                                                                                            href={`${variations.url}`}>
                                                                                            {
                                                                                                variations.title
                                                                                            }
                                                                                        </ALink>
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    </>
                                                                )}
                                                            </Collapse>
                                                        </li>
                                                    </ul>
                                                </>
                                            )}
                                        </Collapse> */}
                  </>
                }
              </li>

              <li className={pathname.startsWith('/product') ? 'active' : ''}>
                {
                  <>
                    <button>Toggle Content</button>
                    {/* <Collapse
                                            isOpen={true}
                                            transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)">
                                            {({
                                                onToggle,
                                                setCollapsibleElement,
                                                toggleState
                                            }) => (
                                                <>
                                                    <ALink
                                                        className={`${toggleState.toLowerCase()}`}>
                                                        Products
                                                        <span
                                                            className="mmenu-btn"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                onToggle();
                                                            }}></span>
                                                    </ALink>

                                                    <ul
                                                        ref={
                                                            setCollapsibleElement
                                                        }>
                                                        <li>
                                                            <>
                                                                <button>
                                                                    Toggle
                                                                    Content
                                                                </button>
                                                                <Collapse
                                                                    isOpen={
                                                                        true
                                                                    }
                                                                    transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)">
                                                                    {({
                                                                        onToggle,
                                                                        setCollapsibleElement,
                                                                        toggleState
                                                                    }) => (
                                                                        <>
                                                                            <ALink
                                                                                href="#"
                                                                                className={`${toggleState.toLowerCase()}`}>
                                                                                PRODUCT
                                                                                PAGES
                                                                                <span
                                                                                    className="mmenu-btn"
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        onToggle();
                                                                                    }}></span>
                                                                            </ALink>
                                                                            <ul
                                                                                ref={
                                                                                    setCollapsibleElement
                                                                                }>
                                                                                {mainMenu.product.pages.map(
                                                                                    (
                                                                                        variations,
                                                                                        index
                                                                                    ) => (
                                                                                        <li
                                                                                            key={
                                                                                                'menu-item' +
                                                                                                index
                                                                                            }>
                                                                                            <ALink
                                                                                                href={`${variations.url}`}>
                                                                                                {
                                                                                                    variations.title
                                                                                                }
                                                                                            </ALink>
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </>
                                                                    )}
                                                                </Collapse>
                                                            </>
                                                        </li>

                                                        <li>
                                                            <>
                                                                <button>
                                                                    Toggle
                                                                    Content
                                                                </button>
                                                                <Collapse
                                                                    isOpen={
                                                                        true
                                                                    }
                                                                    transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)">
                                                                    {({
                                                                        onToggle,
                                                                        setCollapsibleElement,
                                                                        toggleState
                                                                    }) => (
                                                                        <>
                                                                            <ALink
                                                                                href="#"
                                                                                className={`${toggleState.toLowerCase()}`}>
                                                                                PRODUCT
                                                                                LAYOUTS
                                                                                <span
                                                                                    className="mmenu-btn"
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        onToggle();
                                                                                    }}></span>
                                                                            </ALink>
                                                                            <ul
                                                                                ref={
                                                                                    setCollapsibleElement
                                                                                }>
                                                                                {mainMenu.product.layout.map(
                                                                                    (
                                                                                        variations,
                                                                                        index
                                                                                    ) => (
                                                                                        <li
                                                                                            key={
                                                                                                'menu-item' +
                                                                                                index
                                                                                            }>
                                                                                            <ALink
                                                                                                href={`${variations.url}`}>
                                                                                                {
                                                                                                    variations.title
                                                                                                }
                                                                                            </ALink>
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </>
                                                                    )}
                                                                </Collapse>
                                                            </>
                                                        </li>
                                                    </ul>
                                                </>
                                            )}
                                        </Collapse> */}
                  </>
                }
              </li>

              <li className={isOtherPage() ? 'active' : ''}>
                <button>Toggle Content</button>
                {/* <Collapse
                                    isOpen={true}
                                    transition="height 200ms cubic-bezier(0.4, 0, 0.2, 1)">
                                    {({
                                        onToggle,
                                        setCollapsibleElement,
                                        toggleState
                                    }) => (
                                        <>
                                            <a
                                                href="#"
                                                className={`${toggleState.toLowerCase()}`}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    onToggle();
                                                }}>
                                                Pages
                                                <span className="mmenu-btn"></span>
                                            </a>
                                            <ul ref={setCollapsibleElement}>
                                                <li>
                                                    <ALink href="/wishlist">
                                                        Wishlist
                                                    </ALink>
                                                </li>
                                                <li>
                                                    <ALink href="/cart">
                                                        Shopping Cart
                                                    </ALink>
                                                </li>
                                                <li>
                                                    <ALink href="/checkout">
                                                        Checkout
                                                    </ALink>
                                                </li>
                                                <li>
                                                    <ALink href="/account">
                                                        Dashboard
                                                    </ALink>
                                                </li>
                                                <li>
                                                    <ALink href="/login">
                                                        Login
                                                    </ALink>
                                                </li>
                                                <li>
                                                    <ALink href="/forgot-password">
                                                        Forgot Password
                                                    </ALink>
                                                </li>
                                            </ul>
                                        </>
                                    )}
                                </Collapse> */}
              </li>

              <li>
                <ALink href="/blog">Blog</ALink>
              </li>
              <li>
                <ALink href="/about-us">About Us</ALink>
              </li>
            </ul>

            <ul className="mobile-menu">
              <li>
                <ALink href="/account">My Account</ALink>
              </li>
              <li>
                <ALink href="/contact-us">Contact Us</ALink>
              </li>
              <li>
                <ALink href="/blog">Blog</ALink>
              </li>
              <li>
                <ALink href="/wishlist">My Wishlist</ALink>
              </li>
              <li>
                <ALink href="/cart">Cart</ALink>
              </li>
              <li>
                <ALink href="/login" className="login-link">
                  Log In
                </ALink>
              </li>
            </ul>
          </nav>

          <form
            className="search-wrapper mb-2"
            action="#"
            onSubmit={searchProducts}>
            <input
              type="text"
              className="form-control mb-0"
              placeholder="Search..."
              required
              onChange={onChangeSearchText}
            />
            <button
              className="btn icon-search text-white bg-transparent p-0"
              type="submit"></button>
          </form>

          <div className="social-icons">
            <ALink
              href="#"
              className="social-icon social-facebook icon-facebook"></ALink>
            <ALink
              href="#"
              className="social-icon social-twitter icon-twitter"></ALink>
            <ALink
              href="#"
              className="social-icon social-instagram icon-instagram"></ALink>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(MobileMenu);
