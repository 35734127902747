import { actionTypes } from './constants';
import { persistReducer } from 'redux-persist';
import {noopStorage} from '../../utils/storage/noopStorage';

const initialState = {
  currentCustomerData: true,
  customerAddress: {},
  isCustomerAddressSet: false
};

const customerReducer = (state = initialState, action) => {

  // console.log(action.type);
  // console.log(action.payload);

  switch (action.type) {

    case actionTypes.GetCurrentCustomerSuccess:

      return {
        ...state,
        currentCustomerData: action.payload
      };

    case actionTypes.SetCustomerAddress:
      return {
        ...state,
        customerAddress: action.payload,
        isCustomerAddressSet: true
      };


    case actionTypes.SetCustomerState:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };

    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: 'porto-',
  key: 'customer',
  storage: noopStorage
};

export default persistReducer(persistConfig, customerReducer);
