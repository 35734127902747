import React from 'react';

export default function FullheightIframe(props) {
  const { src } = props;

  return (
    <iframe
      frameBorder="0"
      id="iframe-aff"
      name="iframe-aff"
      src={src}
      style={{
        flpositionoat: 'absolute',
        height: '100%',
        width: '100%',
        border: 'none'
      }}
    />
  );
};
