import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from '../../common/ALink';

function CartPopup(props) {
  const { product } = props;

  return (
    <div className="minipopup-area">
      <div className="minipopup-box" style={{ top: '0' }}>
        <div className="product media-with-lazy">
          <figure className="product-media w-100">
            <ALink href={`/product/default${product.slug}`}>
              <LazyLoadImage
                alt="product"
                src={
                  product?.small_pictures?.length
                    ? `${(!product?.small_pictures[0]?.url?.includes?.('https:')
                        ? process.env.NEXT_PUBLIC_ASSET_URI
                        : '') + product?.small_pictures[0]?.url}`
                    : ''
                }
                threshold={500}
                effect="black and white"
                width="100%"
                height="auto"
              />
            </ALink>
          </figure>
          <div className="product-detail">
            {product.index > -1 ? (
              !product.variants[product.index].color ? (
                <ALink
                  className="product-name"
                  href={`/product/default${product.slug}`}>
                  {product.name +
                    ' - ' +
                    product.variants[product.index].size.name}
                </ALink>
              ) : !product.variants[product.index].size ? (
                <ALink
                  className="product-name"
                  href={`/product/default${product.slug}`}>
                  {product.name +
                    ' - ' +
                    product.variants[product.index].color.name}
                </ALink>
              ) : (
                <ALink
                  className="product-name"
                  href={`/product/default${product.slug}`}>
                  {product.name +
                    ' - ' +
                    product.variants[product.index].color.name +
                    ', ' +
                    product.variants[product.index].size.name}
                </ALink>
              )
            ) : (
              <ALink
                className="product-name"
                href={`/product/default${product.slug}`}>
                {product.name}
              </ALink>
            )}

            <p>has been added to your cart.</p>
          </div>
        </div>
        <div className="product-action">
          <ALink href="/cart" className="btn viewcart">
            View Cart
          </ALink>
          <ALink href="/checkout" className="btn btn-dark checkout">
            Checkout
          </ALink>
        </div>
        <button className="mfp-close" onClick={() => props.closeToast()}></button>
      </div>
    </div>
  );
}

export default React.memo(CartPopup);
