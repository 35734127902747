export const shopColors = [
    {
        color: "#000",
        name: "black"
    },
    {
        color: "#0188cc",
        name: "blue"
    },
    {
        color: "#81d742",
        name: "green"
    },
    {
        color: "#6085a5",
        name: "indigo"
    },
    {
        color: "#ab6e6e",
        name: "red"
    }
];

export const shopSizes = [
    {
        size: "XL",
        name: "Extra Large"
    },
    {
        size: "L",
        name: "Large"
    },
    {
        size: "M",
        name: "Medium"
    },
    {
        size: "S",
        name: "Small"
    }
]

export const sections = {
    categories: 'categories',
    price: 'price',
    materials: 'materials',
    colors: 'colors'
};
