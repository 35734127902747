import React, { useState, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, useDotButton } from './useDotButton';
import cn from 'classnames';
import Autoscroll from 'embla-carousel-auto-scroll';
import Autoplay from 'embla-carousel-autoplay';

export function EmblaCarousel({
  children,
  options,
  autoScroll,
  autoPlay,
  showButtons,
  totalSlides,
  slidesToScroll,
  onEmblaApi
}) {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    options, 
    [
      ...(autoScroll ? [Autoscroll({ speed: 1 })] : []),
      ...(autoPlay ? [Autoplay({delay: 5000, stopOnInteraction: false})] : [])
    ]
  );
  const { selectedIndex, onDotButtonClick } = useDotButton(
    emblaApi,
    slidesToScroll
  );

  const numberOfButtons = Math.ceil(totalSlides / slidesToScroll);

  useEffect(() => {
    if (emblaApi && onEmblaApi) {
      onEmblaApi(emblaApi);
    }
  }, [emblaApi, onEmblaApi]);

  return (
    <>
      <div className="embla" ref={emblaRef}>
        {children}

        {showButtons && (
          <div className="embla__controls">
            <div className="embla__dots">
              {numberOfButtons > 1 &&
                Array.from({ length: numberOfButtons }).map((_, index) => (
                  <DotButton
                    key={index}
                    onClick={() => onDotButtonClick(index)}
                    className={cn('embla__dot', {
                      'embla__dot--selected':
                        index === Math.ceil(selectedIndex / slidesToScroll)
                    })}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
