import { useState, useEffect } from 'react';
import { fetchAxios } from '../../utils/fetchAxios/fetchAxios';

/**
 * Custom hook to perform HTTP requests using axios with automatic token handling.
 *
 * @param {string} url - The endpoint URL path to append to the NEXT_PUBLIC_SERVER_URL.
 * @param {Object} requestConfig - Additional axios request configurations. This object can include any Axios request configuration options such as `method`, `headers`, `params`, `data`, and more. These options enable you to fully customize how the HTTP request is made. Common properties include:
 *   - method: Specifies the HTTP method for the request (e.g., 'get', 'post', 'put', 'delete').
 *   - headers: Object representing custom headers for the request. Useful for authentication tokens, content type, etc.
 *   - params: Object containing URL parameters to be sent with the request. Ideal for GET requests with query parameters.
 *   - data: Data to be sent as the request body, applicable for methods like 'post', 'put'.
 *   - timeout: Milliseconds before the request times out. If exceeded, the request is aborted.
 *   - responseType: Type of data the server will respond with (e.g., 'json', 'blob', 'document').
 *
 * Example:
 * {
 *   method: 'get', 'post', 'put', 'delete', 'patch', 'options', or 'head',
 *   headers: {
 *     'Content-Type': 'application/json',
 *     Authorization: 'Bearer your-token-here',
 *   },
 *   params: {
 *     queryParam: 'value'
 *   },
 *   data: {
 *     key: 'value'
 *   }
 * }
 *
 * Note: Not all properties are required for every request; include only what is necessary for your specific needs. This flexibility allows for a wide range of HTTP requests.
 *
 * @param {boolean} executeOnMount - If true, the request is automatically executed on component mount.
 * @returns {Object} - The hook returns an object with the following properties:
 *   - data: Data returned by the request.
 *   - error: Error occurred during the request.
 *   - isLoading: Indicates if the request is in progress.
 *   - refresh: Function to re-execute the request manually.
 *   - execute: Function to execute the request with the provided URL and config.
 */

export function useAxios(
  url,
  requestConfig = {},
  executeOnMount = true,
  onSuccess
) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const updatedRequestConfig = {
    ...requestConfig
  };

  const fetchData = async onSuccessByExecute => {
    setIsLoading(true);
    try {
      const response = await fetchAxios(url, updatedRequestConfig);
      setData(response?.data);
      onSuccess?.(response?.data);
      onSuccessByExecute?.(response?.data);
      return response?.data;
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!executeOnMount) return;
    fetchData();
  }, [executeOnMount, url, JSON.stringify(updatedRequestConfig)]);

  const execute = async (data, paramUrl, onSuccessByExecute) => {
    updatedRequestConfig.data = {
      ...(updatedRequestConfig?.data ? updatedRequestConfig.data : {}),
      ...(data ? data : {})
    };

    if (paramUrl) {
      url = `${url}${paramUrl}`;
    }

    return await fetchData(onSuccessByExecute);
  };

  const refresh = async params => {
    setIsLoading(true)
    if (params) {
      try {
        const response = await fetchAxios(url, {
          params: params,
          paramsSerializer: {
            indexes: null
          }
        },);
        setData(response.data);
        onSuccess?.(response.data);
        return response.data;
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      await fetchData();
    }
  };

  return { data, error, isLoading, refresh ,execute };
}
