import { call, takeEvery, put } from 'redux-saga/effects';
import { actionTypes } from './constants';
import { actions } from './actions';
import { fetchAxios } from '../../utils/fetchAxios/fetchAxios';
import { captureExceptionTrack, eventTrack } from '../../utils/logrocket';

function* getCurrentCustomerSaga() {
  console.log('getCurrentCustomerSaga =>');

  const apiVersion = 1;

  try {

    const { data } = yield call(
      fetchAxios,
      '/public/buyontrust/auth/current_customer?store=furniture',
      {
        method: 'post'
      },
      apiVersion
    );
    console.log('getCurrentCustomerSaga response', data);

    if (data && !data.error) {
      console.log('data.success', data.success);
      eventTrack('getCurrentCustomerSaga => success');
      yield put(actions.getCurrentCustomerSuccess(data || {}));
    } else {
      throw new Error(`getCurrentCustomerSaga returned incorrect response: ${data}`);
    }

    return data;

  } catch (error) {
    console.log('saveOrderExtraDataFailure', error.toString());
    captureExceptionTrack('getCurrentCustomerSaga => error', error.toString());

    // yield put(actions.getCurrentCustomerFailure(error.toString()));
    return error;
  }
}

export function* customerSaga() {
  yield takeEvery(actionTypes.GetCurrentCustomer, getCurrentCustomerSaga);
}
