export const actionTypes = {
  AddToCart: 'ADD_TO_CART',
  RemoveFromCart: 'REMOVE_FROM_CART',
  RefreshCartStore: 'REFRESH_CART_STORE',
  UpdateCart: 'UPDATE_CART',
  GetActualCart: 'GET_ACTUAL_CART',
  SetAvailableCartItems: 'SET_AVAILABLE_CART_ITEMS',
  SetCartItems: 'SET_CART_ITEMS',
  SetInitialCartState: 'SET_INITIAL_CART_STATE',
  SaveCart: 'SAVE_CART'
};
