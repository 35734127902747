import React, { useState, useEffect } from 'react';

function Qty({ min = 1, max = Infinity, onChangeQty, value = 1 }) {
  const [count, setCount] = useState(value);

  useEffect(() => {
    value !== count && setCount(value);
  }, [value]);

  function increase() {
    const newCount = Math.min(max, count + 1);
    setCount(newCount);
    onChangeQty?.(newCount);
  }

  function decrease() {
    const newCount = Math.max(min, count - 1);
    setCount(newCount);
    onChangeQty?.(newCount);
  }

  function changeCount(e) {
    let value = e.target.value ? parseInt(e.target.value) : min;
    const newCount = value < min ? min : value > max ? max : value;
    setCount(newCount);
    onChangeQty?.(newCount);
  }

  return (
    <div className="product-single-qty">
      <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
        <span className="input-group-btn input-group-prepend">
          <button
            className="btn btn-outline btn-down-icon bootstrap-touchspin-down"
            onClick={decrease}
            type="button"
            disabled={count <= min}></button>
        </span>
        <input
          className="horizontal-quantity form-control"
          type="number"
          min="1"
          max={max}
          value={count}
          onChange={changeCount}
        />
        <span className="input-group-btn input-group-append">
          <button
            className="btn btn-outline btn-up-icon bootstrap-touchspin-up"
            onClick={increase}
            type="button"
            disabled={count >= max}></button>
        </span>
      </div>
    </div>
  );
}

export default Qty;
