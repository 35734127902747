import { actionTypes } from './constants';
import { persistReducer } from 'redux-persist';
import {noopStorage} from '../../utils/storage/noopStorage';

const initialState = {
  checkoutData: {},
  eventData: {},
  approvalData: {},
  redirectUrl: '',
  customerData: {},
  providerOrderData: {},
  requestData: {},
  transaction: {},
  orderToCreate: {},
  orderCreatedData: {},
  addressData: {},
  customError: null,
  isPlacingOrder: false,
  isShowStopMes: false,
  isOrderCreated: false,
  orderBCId: '',
  botOrder: null,
  showCongratulationPage: false,
  showCongratulationModal: false,
  orderTrackingUrl: '',
  isSavedOrderExtraData: false,
  extraData: {},
  applicationResponse: {}
};

const checkoutReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.SetCheckoutState:
      // console.log(action.payload.key);
      // console.log(action.payload.value);

      return {
        ...state,
        [action.payload.key]: action.payload.value
      };

    case actionTypes.SaveCheckoutState:
      // console.log('state at SaveCheckoutState', state);
      return state;

    case actionTypes.CreateOrderRequest:
      console.log('CreateOrderRequest');
      return {
        ...state,
        isPlacingOrder: true
      };

    case actionTypes.CreateOrderSuccessComplete.type:
      console.log('createOrderSuccessComplete.type', action.payload);
      return state;

    case actionTypes.CreateOrderSuccess:
      console.log('CreateOrderSuccess', action.payload);

      return {
        ...state,
        // isPlacingOrder: false,
        isOrderCreated: true,
        orderBCId: action.payload.orderBCId
      };

    case actionTypes.CreateOrderFailure:
      console.log('CreateOrderFailure', action.payload);
      return {
        ...state,
        isPlacingOrder: false,
        isOrderCreated: false,
      };
    case actionTypes.GetBotOrderDataComplete.type:
      console.log('getBotOrderDataComplete.type', action.payload);
      return state;

    case actionTypes.GetBotOrderDataSuccess:
      console.log('GetBotOrderDataSuccess', action.payload);

      return {
        ...state,
        botOrder: action.payload.order_data,
        orderTrackingUrl: action.payload.order_data?.order_tracking_url,
        showCongratulationPage: true
      };

    case actionTypes.GetBotOrderDataFailure:
      console.log('GetBotOrderDataFailure', action.payload);
      return {
        ...state,
        showCongratulationModal: true
      };

    case actionTypes.SaveOrderExtraDataComplete.type:
      console.log('SaveOrderExtraDataComplete.type', action.payload);
      return state;

    case actionTypes.SaveOrderExtraDataSuccess:
      console.log('SaveOrderExtraDataSuccess', action.payload);

      return {
        ...state,
        isPlacingOrder: false,
        isSavedOrderExtraData: true
      };

    case actionTypes.SaveOrderExtraDataFailure:
      console.log('SaveOrderExtraDataFailure', action.payload);
      return state;

    case actionTypes.SetInitialCheckoutState:
      return initialState;

    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: 'porto-',
  key: 'checkout',
  storage: noopStorage
};

export default persistReducer(persistConfig, checkoutReducer);
