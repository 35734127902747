import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';

const Wrapper = styled.div`
    position: absolute;
    z-index: 999999999999;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.125) 0px 0.362176px 0.941657px -1px,rgba(0, 0, 0, 0.18) 0px 3px 7.8px -2px;

    :hover {
        cursor: pointer;
    }
    
    Image {
        &:hover {
            width: 15px;
            height: 15px;
        }
    }

    span {
        position: absolute;
        top: 20px;
        right: -1px;
        background: #00aee4;
        color: #fff;
        border-radius: 10px;
        border: 2px solid white;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: bold;
    }
`;

export function Trigger({ count, onClick }) {
  return (
    <Wrapper count={count} onClick={onClick}>
      <Image
        alt="error-log-icon"
        height={22}
        src={count > 0 ? '/images/error-log-fill.svg' : '/images/error-log.svg'}
        width={22}
      />
      <span>{count > 9 ? '9+' : count}</span>
    </Wrapper>
  );
}
