import { persistReducer } from 'redux-persist';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getProviderRequest } from '../api/getProviderWithTrafficAllocation';
import { createSlice } from '@reduxjs/toolkit';
import { noopStorage } from '../utils/storage/noopStorage';

const initialState = {
  providerForUnauthorized: {
    provider: null,
    isLoading: false,
    errors: null,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    getProviderForUnauthorized(state) {
      state.providerForUnauthorized.isLoading = true;
    },
    fetchProviderForUnauthorizedSucceeded(state, action) {
      state.providerForUnauthorized.isLoading = false;
      state.providerForUnauthorized.provider = action.payload.provider;
    },
    fetchProviderForUnauthorizedFailed(state, action) {
      state.providerForUnauthorized.isLoading = false;
      state.providerForUnauthorized.errors = action.payload.message;
    },
  }
});

const { actions, reducer } = appSlice;

export const {
  getProviderForUnauthorized,
  fetchProviderForUnauthorizedSucceeded,
  fetchProviderForUnauthorizedFailed,
} = actions;

export function* appSaga() {
  yield takeEvery(getProviderForUnauthorized, function* saga() {
    try {
      const response = yield call(getProviderRequest);
      yield put(fetchProviderForUnauthorizedSucceeded(response));
    } catch (error) {
      yield put(fetchProviderForUnauthorizedFailed(error));
    }
  });
}

const persistConfig = {
  keyPrefix: 'porto-',
  key: 'app',
  storage: noopStorage
};

export default persistReducer(persistConfig, reducer);