import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

export default function ALink({
  children,
  className,
  style,
  href,
  onClick,
  target,
  ...props
}) {
  if (typeof href === 'object') {
    if (!href.pathname) {
      href.pathname = useRouter().pathname;
    }

    if (href.query && href.query.grid) {
      href.pathname.replace('[grid]', href.query.grid);
    }
  }

  return (
    <>
      {href !== '#' ? (
        <Link legacyBehavior href={href} {...props}>
          <a
            onClick={onClick}
            className={className}
            style={style}
            target={target}>
            {children}
          </a>
        </Link>
      ) : (
        <a onClick={onClick} className={className} style={style} href="#">
          {children}
        </a>
      )}
    </>
  );
}
