import { call, delay, select, takeEvery, put } from 'redux-saga/effects';
import { actionTypes } from './constants';
import Cookies from 'js-cookie';
import { actions } from './actions';
import { fetchAxios } from '../../utils/fetchAxios/fetchAxios';
import { captureExceptionTrack, eventTrack } from '../../utils/logrocket';

function* saveEventDataInfoSaga() {
  console.log('saveEventDataInfoSaga =>');
  eventTrack('saveEventDataInfoSaga =>');

  const apiVersion = 1;

  const { applicationResponse } = yield select(state => state.checkout);

  const requestData = {
    applicationResponse,
    store_type: 'furniture'
  };
  console.log('saveEventDataInfoSaga request', requestData);

  try {
    const { data } = yield call(
      fetchAxios,
      '/public/buyontrust/update_lease_provider_customer_info',
      {
        headers: {
          'X-Auth-Token': Cookies.get('JWT_TOKEN')
        },
        method: 'post',
        data: requestData,
      },
      apiVersion
    );
    console.log('saveEventDataInfoSaga response', data);

    // yield take(actions.saveOrderExtraDataComplete.type);

    if (data && !data.error) {
      console.log(`Save event data info success: ${data.success}`);
    } else {
      throw new Error(`saveEventDataInfoSaga returned incorrect response: ${data}`);
    }
    eventTrack('saveEventDataInfoSaga => success');

    return data;
  } catch (error) {
    console.log('saveEventDataInfoFailure', error.toString());
    captureExceptionTrack('saveEventDataInfoSaga => error', error.toString());

    // yield put(actions.saveOrderExtraDataFailure(error.toString()));
    return error;
  }
}

function* saveOrderExtraDataRequestSaga() {
  console.log('saveOrderExtraDataSaga =>');
  eventTrack('saveOrderExtraDataSaga =>');

  const apiVersion = 1;

  const { extraData } = yield select(state => state.checkout);
  // console.log('extraData', extraData);

  try {
    const { data } = yield call(
      fetchAxios,
      '/public/buyontrust/orders/extra',
      {
        headers: {
          'X-Auth-Token': Cookies.get('JWT_TOKEN')
        },
        method: 'post',
        data: { ...extraData, store_type: 'furniture' },
      },
      apiVersion
    );
    console.log('saveOrderExtraDataSaga response', data);

    // yield take(actions.saveOrderExtraDataComplete.type);

    if (data && !data.error) {
      eventTrack('saveOrderExtraDataSaga => success');
      yield put(actions.saveOrderExtraDataSuccess(data.order_tracking_url || {}));
    } else {
      throw new Error(`saveOrderExtraData returned incorrect response: ${data}`);
    }

    sessionStorage.removeItem('hashUid')

    return data;
  } catch (error) {
    console.log('saveOrderExtraDataFailure', error.toString());
    captureExceptionTrack('saveOrderExtraData => error', error.toString());

    yield put(actions.saveOrderExtraDataFailure(error.toString()));
    return error;
  }
}

function* createOrderSaga({ method, inPutData }) {
  console.log('createOrderSaga =>');
  eventTrack('createOrderSaga =>');

  try {
    const { data } = yield call(
      fetchAxios,
      '/buyontrust/store/create_order',
      {
        headers: {
          'X-Auth-Token': Cookies.get('DD_TOKEN')
        },
        method,
        data: inPutData
      },
    );
    console.log('createOrderSaga response', data);

    if (data && !data.error) {
      eventTrack('createOrderSaga => success');
      yield put(actions.setOrderCreated(data || {}));
    }

    return data;
  } catch (error) {
    captureExceptionTrack('createOrderSaga => error', error.toString());

    return error;
  }
}

// function* getBotOrderDataSaga() {
//   console.log('getBotOrderDataSaga =>');
//
//   const { orderBCId } = yield select(state => state.checkout);
//   console.log('orderBCId', orderBCId);
//
//   const url = `/buyontrust/orders/order-data/${orderBCId}`
//
//   // delay(4000);
//
//   try {
//     const { data } = yield call(
//       fetchAxios,
//       url,
//       {
//         headers: {
//           'X-Auth-Token': Cookies.get('JWT_TOKEN')
//         },
//         method: 'get',
//       },
//     );
//     console.log('getBotOrderDataSaga response', data);
//
//     // yield take(actions.getBotOrderDataComplete.type);
//
//     if (data && !data.error) {
//       yield put(actions.getBotOrderDataSuccess(data || {}));
//     } else {
//       throw new Error(`getBotOrderDataSaga returned incorrect response: ${data}`);
//     }
//
//     return data;
//   } catch (error) {
//     console.log('getBotOrderDataFailure', error.toString());
//     yield put(actions.getBotOrderDataFailure(error.toString()));
//     return error;
//   }
// }

// We predict 3 times getting data with delay if no data received
function* getBotOrderDataSaga() {
  console.log('getBotOrderDataSaga =>');
  eventTrack('getBotOrderDataSaga =>');

  const { orderBCId } = yield select(state => state.checkout);
  console.log('orderBCId', orderBCId);

  const url = `/buyontrust/orders/order-data/${orderBCId}`;

  let attempts = 0;
  const maxAttempts = 3;

  while (attempts < maxAttempts) {

    yield delay(4000);

    try {
      const { data } = yield call(
        fetchAxios,
        url,
        {
          headers: {
            'X-Auth-Token': Cookies.get('JWT_TOKEN')
          },
          method: 'get',
        },
      );
      console.log('getBotOrderDataSaga response', data);

      if (data && !data.error) {
        eventTrack('getBotOrderDataSaga => success');
        yield put(actions.getBotOrderDataSuccess(data || {}));
        return data;
      } else {
        throw new Error(`getBotOrderDataSaga returned incorrect response: ${data}`);
      }
    } catch (error) {
      console.log('getBotOrderDataFailure', error.toString());
      captureExceptionTrack('getBotOrderData => error', error.toString());

      yield put(actions.getBotOrderDataFailure(error.toString()));
      attempts += 1;

      if (attempts >= maxAttempts) {
        return error;
      }
    }
  }
}

export function* saveCheckoutStatusSaga() {
  // console.log('saveCheckoutStatusSaga');

  try {
    // const state = yield select(state => state);
    // console.log('state', state);
    const { checkoutData } = yield select(state => state.checkout);
    // console.log('checkoutData', checkoutData);

    const getDD_TOKEN = () => Cookies.get('DD_TOKEN');

    if (!getDD_TOKEN()) {
      yield put(actions.refreshCheckoutStore());
      return;
    }

    const { data } = yield call(
      fetchAxios,
      '/public/buyontrust/services/state',
      {
        method: 'post',
        data: checkoutData
      },
    );
    // console.log('saveCheckoutStatus response', data);

  } catch (error) {}

}

function* createOrderRequestSaga() {
  console.log('createOrderRequestSaga');
  eventTrack('createOrderRequestSaga');

  try {
    // const state = yield select(state => state);
    // console.log('state', state);
    const { orderToCreate } = yield select(state => state.checkout);
    console.log('orderToCreate', orderToCreate);

    const getDD_TOKEN = () => Cookies.get('DD_TOKEN');

    if (!getDD_TOKEN()) {
      yield put(actions.refreshCheckoutStore());
      return;
    }
    const response = yield call(createOrderSaga, {
      method: 'post',
      inPutData: orderToCreate
    });
    console.log('createOrderSuccess', response);

    // yield take(actions.createOrderSuccessComplete.type);

    if (response && response.orderBCId) {
      eventTrack('createOrder => success');
      yield put(actions.createOrderSuccess({
        orderBCId: response.orderBCId,
      }));
    } else {
      throw new Error(`createOrderRequestSaga returned incorrect response: ${response}`);
    }

  } catch (error) {
    console.log('createOrderFailure', error.toString());
    captureExceptionTrack('createOrder => error', error.toString());

    yield put(actions.createOrderFailure(error.toString()));
    yield put(actions.setOrderNotCreated());

  }
}

export function* checkoutSaga() {
  yield takeEvery(actionTypes.SaveCheckoutState, saveCheckoutStatusSaga);
  yield takeEvery(actionTypes.CreateOrderRequest, createOrderRequestSaga);
  // yield takeEvery(actionTypes.SetOrderNotCreated, orderNotCreatedSaga);
  yield takeEvery(actionTypes.SaveOrderExtraDataRequest, saveOrderExtraDataRequestSaga);
  yield takeEvery(actionTypes.GetBotOrderData, getBotOrderDataSaga);
  yield takeEvery(actionTypes.SaveEventDataInfo, saveEventDataInfoSaga);
}
