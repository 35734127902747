import axios from 'axios';
import axiosRetry from 'axios-retry';
import Cookies from 'js-cookie';
import { clearAllCookies } from '../сookies/cookies';
import { clearLocalStorage } from '../storage/storage';
import { logOut } from '../globalHelpers/globalHelpers';
import { handleFetchError, generalAbortController } from '../index';

const clearStorage = () => {
  clearAllCookies();
  clearLocalStorage();
};

if (process.env.NEXT_AXIOS_RETRY_ENABLED === 'true') {
  axiosRetry(axios, {
    retries: Number(process.env.NEXT_AXIOS_RETRY_MAX_RETRIES) || 3,
    retryCondition: (error) => {
      const retryErrors = process.env.NEXT_AXIOS_RETRY_CONDITION_ERRORS?.split(',') || [];
      return error.response?.status >= 500 ||
        retryErrors.includes(error.code);
    },
    ...(process.env.NEXT_AXIOS_RETRY_RETRY_DELAY && {
      retryDelay: {
        exponential: axiosRetry.exponentialDelay,
        linear: axiosRetry.linearDelay()
      }[process.env.NEXT_AXIOS_RETRY_RETRY_DELAY]
    })
  });
}

export const fetchAxios = async (url, requestConfig, apiVersion = 2) => {
  let baseUrl = process.env.NEXT_PUBLIC_SERVER_URL;
  if (apiVersion === 1) baseUrl = baseUrl.replace('v2', 'v1');
  try {
    const getDD_TOKEN = () => Cookies.get('DD_TOKEN');
    const updatedRequestConfig = {
      url: baseUrl + url,
      signal: generalAbortController.signal,
      ...requestConfig,
      headers: {
        'Content-Type': 'application/json',
        ...(getDD_TOKEN() ? { 'X-Auth-Token': getDD_TOKEN() } : {})
      }
    };

    return await axios(updatedRequestConfig);
  } catch (err) {
    if (err?.response?.data?.isLoggedIn === false) {
      clearStorage();

      if (window.location.pathname.includes('/home') || window.location.pathname.includes('/shop')) {
        window.location.reload();
        return;
      }

      if (window.location.pathname.includes('/cart') || window.location.pathname.includes('/checkout') || window.location.pathname.includes('/account')) {
        window.location.href = `/login?from=${window.location.pathname}`;
        return;
      }

      logOut();
    } else {
      handleFetchError(err);

      if (err?.response?.status === 401) {
        clearStorage();
        window.location.href = `/login?from=${window.location.pathname}`;
      }
    }
  }
};
