import { actionTypes } from './constants';

export const actions = {

  refreshCheckoutStore: data => ({
    type: actionTypes.RefreshCheckoutStore,
    payload: data
  }),
  setCheckoutState: data => ({
    type: actionTypes.SetCheckoutState,
    payload: data
  }),
  saveCheckoutState: data => ({
    type: actionTypes.SaveCheckoutState,
    payload: data
  }),

  createOrderRequest: () => ({
    type: actionTypes.CreateOrderRequest,
  }),
  createOrderSuccess: (data) => ({
    type: actionTypes.CreateOrderSuccess,
    payload: data,
  }),
  createOrderSuccessComplete: () => ({
    type: actionTypes.CreateOrderSuccessComplete.type,
  }),
  createOrderFailure: (error) => ({
    type: actionTypes.CreateOrderFailure,
    payload: error,
  }),

  getBotOrderData: (data) => ({
    type: actionTypes.GetBotOrderData,
    payload: data,
  }),
  getBotOrderDataComplete: () => ({
    type: actionTypes.GetBotOrderDataComplete.type,
  }),
  getBotOrderDataSuccess: (data) => ({
    type: actionTypes.GetBotOrderDataSuccess,
    payload: data,
  }),
  getBotOrderDataFailure: (error) => ({
    type: actionTypes.GetBotOrderDataFailure,
    payload: error,
  }),

  setOrderCreated: (data) => ({
    type: actionTypes.SetOrderCreated,
    payload: data,
  }),
  setOrderNotCreated: (data) => ({
    type: actionTypes.SetOrderNotCreated,
    payload: data,
  }),

  saveOrderExtraDataRequest: (data) => ({
    type: actionTypes.SaveOrderExtraDataRequest,
    payload: data,
  }),
  saveOrderExtraDataComplete: () => ({
    type: actionTypes.SaveOrderExtraDataComplete.type,
  }),
  saveOrderExtraDataSuccess: (data) => ({
    type: actionTypes.SaveOrderExtraDataSuccess,
    payload: data,
  }),
  saveOrderExtraDataFailure: (error) => ({
    type: actionTypes.SaveOrderExtraDataFailure,
    payload: error,
  }),
  saveEventDataInfo: (data) => ({
    type: actionTypes.SaveEventDataInfo,
    payload: data,
  }),
  setInitialCheckoutState: () => ({
    type: actionTypes.SetInitialCheckoutState
  })
};
