import { actionTypes } from './constants';

export const actions = {

  getCurrentCustomer: data => ({
    type: actionTypes.GetCurrentCustomer,
    payload: data
  }),
  getCurrentCustomerSuccess: data => ({
    type: actionTypes.GetCurrentCustomerSuccess,
    payload: data
  }),


  setCustomerAddress: data => ({
    type: actionTypes.SetCustomerAddress,
    payload: data
  }),

  setCustomerState: data => ({
    type: actionTypes.SetCustomerState,
    payload: data
  }),

};
