import LightBox from 'react-18-image-lightbox';
import { useState, useEffect, useCallback } from 'react';
import { Magnifier } from '@vanyapr/react-image-magnifiers';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { EmblaCarousel } from '../../../features/carousel/emblaCarousel';
import cn from 'classnames';

export default function ProductMediaOne(props) {
  const { adClass = 'col-lg-5 col-md-6', product, quickView } = props;
  const [openLB, setOpenLB] = useState(false);
  const [mainEmblaApi, setMainEmblaApi] = useState(null);
  const [thumbEmblaApi, setThumbEmblaApi] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onThumbClick = useCallback(
    index => {
      if (!mainEmblaApi || !thumbEmblaApi) return;
      setSelectedIndex(index);
      mainEmblaApi.scrollTo(index);
      thumbEmblaApi.scrollTo(index - 1)
    },
    [mainEmblaApi]
  );

  useEffect(() => {
    if (!mainEmblaApi) return;

    const onSelect = () => {
      setSelectedIndex(mainEmblaApi.selectedScrollSnap());
      if (thumbEmblaApi) {
        thumbEmblaApi.scrollTo(mainEmblaApi.selectedScrollSnap());
      }
    };

    mainEmblaApi.on('select', onSelect);
    return () => mainEmblaApi.off('select', onSelect);
  }, [mainEmblaApi, thumbEmblaApi]);

  function isSale() {
    return product.price[0] !== product.price[1]
      ? '-' +
          (
            (100 * (product.price[1] - product.price[0])) /
            product.price[1]
          ).toFixed(0) +
          '%'
      : product.variants.find(variant => variant.sale_price)
        ? 'Sale'
        : false;
  }

  function openLightBox() {
    setOpenLB(true);
  }

  function closeLightBox() {
    setOpenLB(false);
  }

  function moveNextPhoto() {
    setSelectedIndex((selectedIndex + 1) % product.large_pictures.length);
  }

  function movePrevPhoto() {
    setSelectedIndex(
      (selectedIndex + product.large_pictures.length - 1) %
        product.large_pictures.length
    );
  }

  return (
    <>
      <div className={`product-single-gallery ${adClass}`}>
        <div className="skel-pro skel-magnifier" />
        {product && (
          <>
            <div className="product-slider-container">
              <div className="label-group">
                {product.is_hot ? (
                  <div className="product-label label-hot">HOT</div>
                ) : (
                  ''
                )}

                {isSale() ? (
                  <div className="product-label label-sale">{isSale()}</div>
                ) : (
                  ''
                )}
              </div>

              <EmblaCarousel
                options={{ loop: false }}
                totalSlides={product.large_pictures.length}
                slidesToScroll={1}
                autoScroll={false}
                autoPlay={false}
                showButtons={false}
                onEmblaApi={setMainEmblaApi}
              >
                <div className="product-main-image">
                  {product.large_pictures.map((item, index) => (
                    <div className="product-single-image" key={index}>
                      <Magnifier
                        cursorStyleActive="crosshair"
                        dragToMove={false}
                        imageAlt="product"
                        imageSrc={
                          (!item.url.includes('https:')
                            ? process.env.NEXT_PUBLIC_ASSET_URI
                            : '') + item.url
                        }
                        mouseActivation="hover"
                      />
                    </div>
                  ))}
                </div>
              </EmblaCarousel>

              <span className="prod-full-screen" onClick={openLightBox}>
                <i className="icon-plus" />
              </span>
            </div>

            <EmblaCarousel
              options={{ dragFree: true }}
              totalSlides={product.pictures.length}
              slidesToScroll={1}
              autoScroll={false}
              autoPlay={false}
              showButtons={false}
              onEmblaApi={setThumbEmblaApi}
            >
              <div className="prod-thumbnail">
                {product.pictures.map((item, index) => (
                  <div
                    className={cn('embla-dot media-with-lazy', {'active': index === selectedIndex})}
                    key={`embla-dot-${index}`}
                    onClick={() => onThumbClick(index)}>
                    <LazyLoadImage
                      alt="Thumbnail"
                      className={cn('d-block', { 'quick-view': quickView })}
                      height="auto"
                      src={
                        (!item?.url?.includes?.('https:')
                          ? process.env.NEXT_PUBLIC_ASSET_URI
                          : '') + item.url
                      }
                      width="100%"
                    />
                  </div>
                ))}
              </div>
            </EmblaCarousel>

            {openLB && (
              <LightBox
                mainSrc={
                  (!product.large_pictures[selectedIndex].url?.includes?.(
                    'https:'
                  )
                    ? process.env.NEXT_PUBLIC_ASSET_URI
                    : '') + product.large_pictures[selectedIndex].url
                }
                nextSrc={
                  (!product.large_pictures[
                    (selectedIndex + 1) % product.large_pictures.length
                  ].url?.includes?.('https:')
                    ? process.env.NEXT_PUBLIC_ASSET_URI
                    : '') +
                  product.large_pictures[
                    (selectedIndex + 1) % product.large_pictures.length
                  ].url
                }
                onCloseRequest={closeLightBox}
                onMoveNextRequest={moveNextPhoto}
                onMovePrevRequest={movePrevPhoto}
                prevSrc={
                  process.env.NEXT_PUBLIC_ASSET_URI +
                  product.large_pictures[
                    (selectedIndex + product.large_pictures.length - 1) %
                      product.large_pictures.length
                  ].url
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
