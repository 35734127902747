export const actionTypes = {
  RefreshCheckoutStore: 'REFRESH_CHECKOUT_STORE',
  SaveCheckoutState: 'SAVE_CHECKOUT_STATE',
  SetCheckoutState: 'SET_CHECKOUT_STATE',

  CreateOrderRequest: 'CREATE_ORDER_REQUEST',
  CreateOrderSuccessComplete: 'CREATE_ORDER_SUCCESS_COMPLETE',
  CreateOrderSuccess: 'CREATE_ORDER_SUCCESS',
  CreateOrderFailure: 'CREATE_ORDER_FAILURE',

  GetBotOrderData: 'GET_BOT_ORDER_DATA',
  GetBotOrderDataComplete: 'GET_BOT_ORDER_DATA_COMPLETE',
  GetBotOrderDataSuccess: 'GET_BOT_ORDER_DATA_SUCCESS',
  GetBotOrderDataFailure: 'GET_BOT_ORDER_DATA FAILURE',

  SetOrderCreated: 'SET_ORDER_CREATED',
  SetOrderNotCreated: 'SET_ORDER_NOT_CREATED',

  SaveOrderExtraDataRequest: 'SAVE_ORDER_EXTRA_DATA_REQUESTS',
  SaveOrderExtraDataComplete: 'SAVE_ORDER_EXTRA_DATA_COMPLETE',
  SaveOrderExtraDataSuccess: 'SAVE_ORDER_EXTRA_DATA_SUCCESS',
  SaveOrderExtraDataFailure: 'SAVE_ORDER_EXTRA_DATA_FAILURE',

  SaveEventDataInfo: 'SAVE_EVENT_DATA_INFO',
  SetInitialCheckoutState : 'SET_INITIAL_CHECKOUT_STATE'
};
